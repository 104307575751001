<template>
  <ion-header class="headerTop">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button @click="closeModal()">
                <ion-icon class="closebutton" src="assets/ICONOS/atras.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
    <ion-content class="contentt" >
        <video controls autoplay muted class="video_p">
            <source :src="video" type="video/mp4">
        </video>
    </ion-content>
  </ion-header>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'videoModal',
  props: {
    title: { type: String, default: 'Super Modal' },
    video: { type: String, default: 'Link del Video' },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  methods: {
      closeModal() {
          modalController.dismiss({dismissed: true});
      }
  },
  setup() {
      return {close,};
  },
});
</script>

<style scoped>
.closebutton{
    color: #FFF;
}
.video_p{
    width: 100%;
    min-height: 300px;
}
.contentt{
    height: calc(100vh - 55px);
}

@media only screen and (min-width: 767px) {
    .video_p{
        max-width: 445px;
        margin: auto;
        display: block;
    }
}
</style>