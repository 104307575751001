<template>
  
      <ion-toolbar class="header_app header_app_studio">
        <ion-buttons slot="start">
          <ion-menu-button class="conf_toolbar" menu="menustudio">
            <ion-img src="assets/ICONOS/dex_tabs.png" ></ion-img>
          </ion-menu-button>
        </ion-buttons>
        <ion-buttons class="logo_toolbar logo_toolbar_studio" slot="end" style="">
          <ion-img src="assets/ICONOS/Servicios/vybroo-studio.svg"></ion-img>
        </ion-buttons>
        <ion-menu-button class="dex_tabs" slot="end" menu="menudex">
            <ion-icon class="i_conf" src="assets/ICONOS/configuracion.svg" ></ion-icon>
            <ion-icon class="i_plus" src="assets/ICONOS/plus.svg" ></ion-icon>
        </ion-menu-button>
        <ion-title>{{ $route.params.id }}</ion-title>
      </ion-toolbar>
      
</template>

<script>
export default {
    name:"headertoolbarstudio"
}
</script>
